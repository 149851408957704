import React from "react"
import { graphql } from "gatsby"
import Layout from '../../components/layout'
import CasesGrid from '../../components/cases_grid'

const ArchiveTopPage = ({ data }) => {
  return (
    <Layout pageTitle="アーカイブ室">
      <CasesGrid data={data}></CasesGrid>
    </Layout>
  )
}

export const query = graphql`
  {
    allAirtable(
      filter: {table: {eq: "Cases"}}
      sort: {fields: data___Id, order: DESC}
    ) {
      nodes {
        data {
          Name
          Photos {
            localFiles {
              childImageSharp {
                gatsbyImageData(breakpoints: [300, 600, 900])
              }
            }
          }
          Taken_by
          Id
        }
      }
    }
  }
`

export default ArchiveTopPage