import * as React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { SimpleGrid, Box, LinkBox, LinkOverlay, AspectRatio, Text } from '@chakra-ui/react'

const CasesGrid = ({ data }) => {
  return (
    <SimpleGrid
      columns={[2, 3, 3]}
      spacingX={['10px', '10px', '20px']}
      spacingY={['30px', '30px', '40px']}
    >
    {
      data.allAirtable.nodes.map((node) => (
      <LinkBox boxShadow='md'>
        <AspectRatio ratio={5/4}>
          <GatsbyImage 
            image={getImage(node.data.Photos.localFiles[0])}
            layout="constrained"
            placeholder="blurred"
          />
        </AspectRatio>
          <Box px="3" py="4">
            <LinkOverlay href={`/archive/${node.data.Id}`}>
              <Text fontSize={{ base: 'sm', sm: 'md' }} noOfLines={{ base: 2, sm: 1 }}>
                {node.data.Name}
              </Text>
            </LinkOverlay>
          </Box>
        </LinkBox>
      ))
    }
    </SimpleGrid>
  )
}

export default CasesGrid